
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import { getResourceCollection } from "next-drupal";
import Layout from "components/Layout";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import { formatDateYMD } from "lib/formatDate";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";

const NewsHomeSection = dynamic(() =>
  import("components/Section/NewsHomeSection")
);
const EventHomeSection = dynamic(() =>
  import("components/Section/EventHomeSection")
);
const PublicationHomeSection = dynamic(() =>
  import("components/Section/PublicationHomeSection")
);
const MayorHomeSection = dynamic(() =>
  import("components/Section/MayorHomeSection")
);

export default function IndexPage({ nodes, globals }) {
  const { t } = useTranslation("home");

  const hasNews = nodes.newses?.length > 0;
  const hasEvents = nodes.events?.length > 0;
  const hasPublications = nodes.publications?.length > 0;
  const hasMayorWord = nodes.officials?.length > 0;

  return (
    <Layout globals={globals} banners={nodes.banners} home>
      <Meta title={t("meta.title")} description={t("meta.description")} />
      {hasNews && <NewsHomeSection nodes={nodes.newses} />}
      {hasEvents && <EventHomeSection nodes={nodes.events} />}
      {(hasPublications || hasMayorWord) && (
        <section className="relative overflow-hidden">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {hasPublications && (
                <PublicationHomeSection node={nodes.publications[0]} />
              )}
              {hasMayorWord && <MayorHomeSection node={nodes.officials[0]} />}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

 async function _getStaticProps(context) {
  const now = formatDateYMD();
  const nowTimeStamp = Date.now() / 1000;

  const bannersParams = new DrupalJsonApiParams();

  bannersParams
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addSort("created", "DESC")
    .addPageLimit(5);

  const banners = await getResourceCollection("node--banner", {
    params: bannersParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const newsesParams = new DrupalJsonApiParams();

  newsesParams
    .addInclude(["field_image", "field_type"])
    .addFilter("status", 1)
    .addFilter("promote", 1)
    .addFilter("langcode", context.locale)
    .addFilter("created", nowTimeStamp, "<=")
    .addSort("sticky", "DESC")
    .addSort("created", "DESC")
    .addPageLimit(3);

  const newses = await getResourceCollection("node--news", {
    params: newsesParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const eventsParams = new DrupalJsonApiParams();

  eventsParams
    .addGroup("forthcoming", "OR")
    .addInclude(["field_image"])
    .addFilter("status", 1)
    .addFilter("field_date_end", now, ">=", "forthcoming")
    .addFilter("field_date_start", now, ">=", "forthcoming")
    .addFilter("langcode", context.locale)
    .addPageLimit(2)
    .addSort("sticky", "DESC")
    .addSort("field_date_start", "ASC");

  const events = await getResourceCollection("node--event", {
    params: eventsParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const publicationsParams = new DrupalJsonApiParams();

  publicationsParams
    .addInclude(["field_image", "field_file"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addSort("created", "DESC")
    .addSort("title", "ASC")
    .addPageLimit(1);

  const publications = await getResourceCollection("node--publication", {
    params: publicationsParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  const officialsParams = new DrupalJsonApiParams();

  officialsParams
    .addInclude(["field_image", "field_type"])
    .addFilter("status", 1)
    .addFilter("langcode", context.locale)
    .addFilter("field_type.id", "087c541d-2ab9-4f0e-9405-a86a9f83cbb0")
    .addSort("created", "DESC")
    .addPageLimit(1);

  const officials = await getResourceCollection("node--official", {
    params: officialsParams.getQueryObject(),
    locale: context.locale,
    defaultLocale: context.defaultLocale,
  });

  return {
    props: {
      globals: await getGlobals(context),
      nodes: {
        banners,
        newses,
        events,
        publications,
        officials,
      },
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  